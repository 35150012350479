//------------------------------------------------------------------
//	BOOTSTRAP SETUP
//------------------------------------------------------------------
@import 'common/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/badge';

//------------------------------------------------------------------
//	CORE ADMIN STYLES
//------------------------------------------------------------------
@import 'node_modules/mlmi-library/components/admin/v1/admin';

.accordion-cols {
  & > .acf-input {
    display: flex;

    .acf-field {
      width: 100%;

      .acf-label {
        display: none;
      }

      &.smaller-editor > .acf-input {
        margin-top: 0;
      }
    }

    .acf-field + .acf-field {
      margin-left: 15px;
    }
  }
}
